import React, { useState } from 'react';

import './LongevityPage.css'

import civitasLogo from './images/civitasLogo.png';
//import heroImage from './images/LongevityHero.png';
import qrImage from './images/CivitasQrCode.png';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        {children}
      </div>
    </div>
  );
};

const CivitasPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);

  const handleBurgerClick = (e) => {
    setIsMenuOpen(e.target.checked);
  };

  return (
    <div className="container">
      <div className="glass-header">
        <div className="header-content">
          <div className="logo-container">
            <img
              src={civitasLogo}
              alt="Civitas Logo"
              className="logo-image"
              style={{
                borderRadius: '20%',
              }}
              onClick={() => window.scrollTo(0, 0)}
            />
          </div>

          <div className="header-controls">
            <button
              className="qr-button"
              onClick={() => setIsQRModalOpen(true)}
            >
              <span>Download</span>
            </button>

            <label className="burger" htmlFor="burger">
              <input
                type="checkbox"
                id="burger"
                checked={isMenuOpen}
                onChange={handleBurgerClick}
              />
              <span></span>
              <span></span>
              <span></span>
            </label>
          </div>

          {isMenuOpen && (
            <div className="dropdown-menu">
              {/* <div className="menu-item" onClick={() => window.scrollTo(0, 0)}>Home</div> */}
              <div
                className="menu-item"
                onClick={() => document.querySelector('.features-section').scrollIntoView({ behavior: 'smooth' })}
              >
                Features
              </div>
              <div
                className="menu-item"
                onClick={() => window.open('https://apps.apple.com/us/app/civitas-civilization-game/id6502847538?itscg=30200&itsct=apps_box_link&mttnsubad=6502847538', '_blank')}
              >
                Download
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal isOpen={isQRModalOpen} onClose={() => setIsQRModalOpen(false)}>
        <div className="qr-container">
          <h2 className="qr-title">Scan to Download</h2>
          <img
            src={qrImage}
            alt="QR Code"
            className="qr-code"
          />
          <p className="qr-description">Scan this QR code with your mobile device to download the app</p>
        </div>
      </Modal>


      <div className="hero-container">
        <div className="hero-wrapper">
          <div className="hero-text-content">
            <h1 className="hero-title">Civitas</h1>
            <p className="hero-description">
            Civitas is a simple but engaging strategy game that challenges you to build, explore, expand, and conquer!
            </p>
            <a
              href="https://apps.apple.com/us/app/civitas-civilization-game/id6502847538?itscg=30200&itsct=apps_box_link&mttnsubad=6502847538"
              style={styles.appStoreButtonContainer}
            >
              <img
                src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1730073600"
                alt="Download on the App Store"
                style={styles.appStoreButtonOfficial}
              />
            </a>
          </div>
          {/* <div className="hero-image-container">
            <img
              src={heroImage}
              alt="Longevity Checklist"
              className="hero-image"
            />
          </div> */}
        </div>

        <div className="features-section">
          <h3 className="features-title">Features</h3>
          <div className="features-cards">
            <div className="feature-card">
              <h4>FREE EARLY ACCESS</h4>
              <p>Enjoy the full game at no cost during our early access period, and be among the first to influence the direction of the game.</p>
            </div>
            <div className="feature-card">
              <h4>STRATEGY</h4>
              <p>Master the art of warfare, diplomacy, and resource management as you guide your civilization from humble beginnings to a powerful empire.</p>
            </div>
            <div className="feature-card">
              <h4>TECH TREE</h4>
              <p>Advance your civilization by researching new technologies. Unlock advanced military units and new building projects, as you progress through the tech tree.</p>
            </div>
            <div className="feature-card">
              <h4>EVOLVING GAMEPLAY</h4>
              <p>With regular updates, new features, and exciting challenges on the horizon, every play session brings fresh opportunities to test your skills.</p>
            </div>
            <div className="feature-card">
              <h4>COMMUNITY DRIVEN DEVELOPMENT</h4>
              <p>As an early access release, Civitas is a work in progress. Your feedback is invaluable! Join our growing community of players, share your insights, and help shape the future of the game.</p>
            </div>
          </div>
          {/* <p className="cta-text">Start Your Longevity Journey Today!</p> */}
        </div>
      </div>

      <footer className="footer">
        <div className="footer-container">
          {/* <div className="footer-section">
            <h4>About</h4>
            <p>The Longevity Checklist app helps you build healthy habits for a longer, better life.</p>
          </div>
          <div className="footer-section">
            <h4>Contact</h4>
            <p>Email: support@longevityapp.com</p>
            <p>Phone: +1 (555) 123-4567</p>
          </div> */}
          <div className="footer-section">

            <div className="social-icons">
                {/* <p>Contact Us</p> */}
              {/* <a href="#"><img src="path/to/facebook-icon.png" alt="Facebook" /></a> */}
              {/* <a href="https://x.com/civitasgame_?s=21">𝕏</a> */}
              {/* <a href="#"><img src="path/to/instagram-icon.png" alt="Instagram" /></a> */}
            </div>
          </div>
        </div>
        <p className="footer-credits">Made with Love ❤️</p>
        {/* <p className="footer-credits">© 2025 Tantalum Technology Corp. All Rights Reserved.</p> */}
      </footer>
    </div>
  );
};

const styles = {
  appStoreButtonContainer: {
    marginBottom: '20px',
  },
  appStoreButtonOfficial: {
    width: '170px',
    height: 'auto',
  }
};

export default CivitasPage;